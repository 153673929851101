import { encodeAbiParameters, zeroHash } from "viem"
import { getMerkleTree, getProof, Leaf } from "./merkleTree"

export enum RaffleStatus {
  Pending = 0,
  Active = 1,
  Closed = 2,
  FCFS = 3,
  Aborted = 4,
}

export enum RaffleType {
  Raffle = 0,
  Gachapon = 1,
  Vote = 2,
}

export enum Action {
  Raffle = 0,
  Mint = 1,
}

type RaffleArgs = (
  | {
      numberOfTokens?: undefined
      tokenIds: number[] | bigint[]
    }
  | {
      numberOfTokens: number | bigint
      tokenIds?: undefined
    }
) & {
  quantities: number[] | bigint[]
  startTime: number | Date
  endTime: number | Date
  raffleType: RaffleType
} & (
    | {
        merkleRoot?: undefined
        allowlist: { address: string; quantity: number }[]
      }
    | {
        allowlist?: undefined
        merkleRoot: string
      }
  )
/**
 * Helper fu nction to compute the arguments for creating a raffle.
 * @param merkleRoot The merkle root of the raffle
 * @param numberOfTokens The number of tokens to create
 * @param quantities The quantities of each token
 * @param startTime The start time of the raffle
 * @param endTime The end time of the raffle
 * @param raffleType The type of raffle to create
 */
export function computeCreateRaffleArgs({
  merkleRoot,
  allowlist,
  quantities,
  tokenIds,
  numberOfTokens,
  startTime,
  endTime,
  raffleType = RaffleType.Raffle,
}: RaffleArgs) {
  let merkleRoot_ = merkleRoot === null ? zeroHash : merkleRoot
  if (allowlist) {
    const tree = getMerkleTree(allowlist)
    merkleRoot_ = tree.getHexRoot()
  }
  return [
    merkleRoot_ as string,
    (numberOfTokens || tokenIds) as any,
    quantities,
    startTime instanceof Date
      ? Math.floor(startTime.getTime() / 1000)
      : startTime,
    endTime instanceof Date ? Math.floor(endTime.getTime() / 1000) : endTime,
    raffleType,
  ] as const
}

/**
 * Helper fu nction to compute the data for entering a raffle.
 * @param raffleId The ID of the raffle
 * @param merkleOpts Optional merkle tree options
 */
export const computeRaffleData = (
  raffleId: number | bigint | string,
  merkleOpts?: {
    allowlist: Leaf[]
    address: string
  },
) => {
  let raffleData

  if (!merkleOpts) {
    raffleData = encodeAbiParameters(
      [{ type: "uint256" }, { type: "bytes32[]" }],
      [0n, []],
    )
  } else {
    const { address, allowlist } = merkleOpts
    const { proof, leaf } = getProof(address, allowlist)
    if (allowlist.length > 0 && (!proof || !leaf)) {
      throw new Error("Address not found in merkle tree")
    }
    raffleData = encodeAbiParameters(
      [{ type: "uint256" }, { type: "bytes32[]" }],
      [BigInt(leaf?.quantity || 1), proof || []],
    )
  }

  return encodeAbiParameters(
    [{ type: "uint256" }, { type: "uint256" }, { type: "bytes" }],
    [BigInt(raffleId), BigInt(Action.Raffle), raffleData],
  )
}

/**
 * Helper fu nction to compute the data for minting remaining items.
 * @param raffleId The ID of the raffle
 * @param tokenId The quantity to mint
 */
export const computeMintData = (
  raffleId: number | bigint | string,
  tokenId: number | bigint,
) => {
  const mintData = encodeAbiParameters([{ type: "uint256" }], [BigInt(tokenId)])

  return encodeAbiParameters(
    [{ type: "uint256" }, { type: "uint256" }, { type: "bytes" }],
    [BigInt(raffleId), BigInt(Action.Mint), mintData],
  )
}

export interface Raffle {
  id: number
  merkleRoot: string
  entries: string[]
  tokenIds: number[]
  quantities: number[]
  remainingQuantities: number[]
  raffleType: RaffleType
  startTime: Date
  endTime: Date
  status: RaffleStatus
  rafflers: Record<`0x${string}`, number>
  tokens: Record<number, number>
}

export function consolidateRaffle(
  raffleData: any,
  [tokenIds, quantities, remainingQuantities, entries, status]: [
    bigint[],
    bigint[],
    bigint[],
    string[],
    bigint,
    // eslint-disable-next-line array-bracket-newline
  ],
): Raffle {
  const id = Number(raffleData[0])
  if (id === 0) {
    throw new Error("Raffle not found")
  }
  const tokens = tokenIds.reduce(
    (acc, tokenId, index) => {
      acc[Number(tokenId)] = Number(quantities[index])
      return acc
    },
    {} as Record<number, number>,
  )
  const rafflers = entries.reduce(
    (acc, entry) => ({
      ...acc,
      [entry]: (acc[entry] || 0) + 1,
    }),
    {} as Record<`0x${string}`, number>,
  )

  return {
    id,
    merkleRoot: raffleData[1],
    entries: entries,
    tokenIds: tokenIds.map(Number),
    quantities: quantities.map(Number),
    remainingQuantities: remainingQuantities.map(Number),
    raffleType: Number(raffleData[2]),
    startTime: new Date(Number(raffleData[3]) * 1000),
    endTime: new Date(Number(raffleData[4]) * 1000),
    status: Number(status) as RaffleStatus,
    rafflers,
    tokens,
  }
}

export function getRaffleStatus(raffle: Raffle): RaffleStatus {
  const now = new Date()
  if (now < raffle.startTime) {
    return RaffleStatus.Pending
  } else if (now >= raffle.startTime && now < raffle.endTime) {
    return RaffleStatus.Active
  }
  return RaffleStatus.Closed
}
