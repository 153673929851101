import { encodePacked, zeroHash } from "viem"
import { getMerkleTree } from "./merkleTree"

type VoteParams = {
  maxSelectableOptions: number
  startTime: Date | null
  endTime: Date
} & (
  | {
      numberOfOptions: number
      tokenIds?: undefined
    }
  | {
      numberOfOptions?: undefined
      tokenIds: number[]
    }
)

interface Voter {
  address: string
  quantity: number // voting power
}

export enum VoteStatus {
  Pending = 0,
  Active = 1,
  Ended = 2,
  Aborted = 3,
}

export type VotingSession = ReturnType<typeof consolidateVotingSession>

/**
 * Consolidates the voting session data from the contract into a single object
 * @param votingSession contract.votingSessions(i)
 * @param votes contract.getVotes(i)
 * @param voters contract.getVoters(i)
 * @returns The consolidated voting session data
 */
export const consolidateVotingSession = (
  votingSession,
  votes,
  voters,
  myVotes,
) => {
  const tokenVotes = votes[0].reduce((acc, tokenId, index) => {
    acc[tokenId] = Number(votes[1][index])
    return acc
  }, {})
  const tokenIds = votes[0] as number[]
  const startTime = new Date(Number(votingSession[1]) * 1000)
  const endTime = new Date(Number(votingSession[2]) * 1000)
  const status = getVoteStatus(startTime, endTime)
  const votesAggregated = votes[1].map(Number)
  let winnerIndexes: null | number[] = null
  if (status === VoteStatus.Ended) {
    winnerIndexes = votesAggregated
      .map((votes, index) => ({
        votes,
        index,
      }))
      .sort((a, b) => b.votes - a.votes)
      .map(({ index }) => index)
  }
  return {
    id: Number(votingSession[0]),
    merkleRoot: votingSession[4],
    hasWhitelist: votingSession[4] !== zeroHash,
    maxSelectableOptions: Number(votingSession[3]),
    startTime,
    endTime,
    status,
    winnerIndexes,
    tokenVotes,
    tokenIds: tokenIds.map(Number),
    votesAggregated,
    myVotes: myVotes?.map(Number) as number[],
    voters: voters[0].reduce((acc, _, i) => {
      return [
        ...acc,
        {
          address: voters[0][i],
          __votes: voters[1][i],
          votes: tokenIds.reduce((acc, tokenId, index) => {
            acc[tokenId] = Number(voters[1][i][index])
            return acc
          }, {}),
        },
      ]
    }, []),
  }
}

export const packVoterLeaf = ({ address, quantity }) =>
  encodePacked(["address", "uint"], [address, quantity])

/**
 * Formulates the arguments for the createVotingSession function
 * @param allowedVoters { address, quantity } The allowed voters for the vote
 * @param voteParams The vote parameters
 * @returns The SolidityVote arguments to be passed to the contract call
 */
export const formCreateVotingSessionArgs = (
  allowedVoters: Voter[],
  {
    maxSelectableOptions,
    numberOfOptions,
    tokenIds,
    startTime,
    endTime,
  }: VoteParams,
): [string, number, number, number, number] => [
  allowedVoters.length > 0
    ? getMerkleTree(allowedVoters, packVoterLeaf).getHexRoot()
    : zeroHash,
  (numberOfOptions || tokenIds) as any,
  maxSelectableOptions,
  startTime ? Math.floor(startTime.getTime() / 1000) : 0,
  Math.floor(endTime.getTime() / 1000),
]

// should be inferrable from the LoomVote ABI but w/e
type SolidityVote = [bigint, bigint[]]
export const formVoteArgs = (
  voteId: number | bigint,
  votes: {
    [tokenId: number]: number | bigint
  },
  tokenIds: number[],
): SolidityVote => {
  const voteArray: bigint[] = new Array(tokenIds.length).fill(BigInt(0))
  Object.entries(votes).forEach(([tId, count]) => {
    const tIndex = tokenIds?.findIndex((t) => Number(t) === Number(tId))
    if (tIndex === -1) {
      throw new Error(`TokenId ${tId} not found in options`)
    }
    voteArray[tIndex] = BigInt(count)
  })

  return [BigInt(voteId), voteArray]
}

/**
 * Determines the current status of a vote based on the current time
 * @param startTime The start time of the vote
 * @param endTime The end time of the vote
 * @returns The current status of the vote
 */
export const getVoteStatus = (startTime: Date, endTime: Date): VoteStatus => {
  const now = new Date()
  if (endTime === startTime) {
    return VoteStatus.Aborted
  }
  if (now < startTime) {
    return VoteStatus.Pending
  } else if (now >= startTime && now < endTime) {
    return VoteStatus.Active
  }
  return VoteStatus.Ended
}
