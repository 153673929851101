import { MerkleTree } from "merkletreejs"
import { encodePacked, keccak256 } from "viem"

export const merkleHash = (v) => keccak256(v)

export type Leaf = {
  address: string
  quantity: number
  [key: string]: any
}

export const getMerkleTree = <T extends Leaf>(
  rawLeaves: T[],
  packLeaf_: (v: T) => string = packLeaf,
) => {
  // TODO: throw if duplicate address?
  const leaves = rawLeaves.map((v) => merkleHash(packLeaf_(v)))
  const tree = new MerkleTree(leaves, keccak256, { sort: true })
  return tree
}

export const packLeaf = ({ address, quantity }) =>
  encodePacked(["address", "uint256"], [address, quantity])

/**
 * Returns the proof for a given address
 * @param address The address to get the proof for
 * @param leaves The raw leaves to generate the tree from
 * @param packLeaf The function to pack the leaf
 * @returns The proof or null if not found
 *
 * @example
 * const proof = getProof(address, rawLeaves, tree, packLeaf)
 */
export const getProof = <T extends Leaf>(
  address: string | undefined,
  leaves: T[],
  packLeaf_: (v: T) => string = packLeaf,
) => {
  const tree = getMerkleTree(leaves, packLeaf_)
  if (leaves.length === 0) {
    return {
      tree,
      leaves,
      proof: [],
      leaf: null,
    }
  }
  if (!address) {
    return {
      tree,
      leaves,
      proof: null,
      leaf: null,
    }
  }
  const leaf = leaves.find((v) => v?.address === address)
  if (!leaf) {
    return {
      tree,
      leaves,
      proof: null,
      leaf: null,
    }
  }
  const packedLeaf = packLeaf_(leaf)
  return {
    tree,
    leaves,
    proof: tree.getHexProof(merkleHash(packedLeaf)) as `0x${string}`[],
    leaf,
  }
}
